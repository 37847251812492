import React from "react";
import Preview from "../layout/preview";

export default function CustomersPreview({ customer, openPreview, setOpenPreview }) {
    const [editEnabled, setEditEnabled] = React.useState(false);

    const toggleEditEnabled = (e) => {
        return setEditEnabled(!editEnabled);
    };

    return (
        <Preview openPreview={openPreview} setOpenPreview={setOpenPreview} setEditEnabled={setEditEnabled}>
            {customer === null ? (
                <div className="h2">Loading...</div>
            ) : (
                <>
                    <div className="h2">#{customer.id}</div>
                    <div className="preview-toolbar">
                        <a type="button" aria-label={`Phone ${customer.name}`} title={`Phone ${customer.name}`} href={`tel:${customer.phone}`}>
                            <span className="material-symbols-rounded">phone</span>
                        </a>
                        <a type="button" aria-label={`Email ${customer.name}`} title={`Email ${customer.name}`} href={`mailto:${customer.email}`}>
                            <span className="material-symbols-rounded">email</span>
                        </a>
                        <button type="button" aria-label={`Expand ${customer.name}`} title={`Expand ${customer.name}`}>
                            <span className="material-symbols-rounded">output</span>
                        </button>
                        <button type="button" onClick={toggleEditEnabled} aria-label={`Edit ${customer.name}`} title={`Edit ${customer.name}`}>
                            <span className="material-symbols-rounded edit_square">edit_square</span>
                        </button>
                        <button type="button" aria-label={`Delete ${customer.name}`} title={`Delete ${customer.name}`}>
                            <span className="material-symbols-rounded">delete</span>
                        </button>
                    </div>
                    <form>
                        <fieldset disabled={!editEnabled}>
                            <div className="form-group">
                                <label>Customer:</label>
                                <input className="form-control" value={customer.name} readOnly></input>
                            </div>
                            <div className="form-group">
                                <span className="label">Consumers:</span>
                                <div className="form-control">
                                    {customer?.consumers?.map((consumer) => (
                                        <span key={`${customer.id}-${consumer.id}`}>
                                            {consumer.first_name} {consumer.last_name},{" "}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Address:</label>
                                <input className="form-control" value={customer.address} readOnly></input>
                                <input className="form-control" value={customer.address2} readOnly></input>
                                <input className="form-control" value={customer.city} readOnly></input>
                                <input className="form-control" value={customer.county} readOnly></input>
                                <input className="form-control" value={customer.postcode} readOnly></input>
                            </div>
                        </fieldset>
                        {editEnabled && <button className="btn btn-outline-white">Update</button>}
                    </form>
                </>
            )}
        </Preview>
    );
}
