import React from 'react';
import Aside from "./aside";
import Footer from "./footer";
import Header from "./header";

export default function Layout({ children, mainClass }) {
    const [openNav, setOpenNav] = React.useState(false);
    
    return (
        <>
            <Header openNav={openNav} setOpenNav={setOpenNav} />
            <main className={mainClass}>
                {children}
            </main>
            <Footer />
            <Aside openNav={openNav} setOpenNav={setOpenNav} />
        </>
    );
}
