import React from "react";
import navigationData from "../data/navigation.json";
import Submenu from "./submenu";

export default function Aside({ openNav, setOpenNav }) {
    const navigation = navigationData.navigation;
    const profileImage = true;
    const toggleMenu = (e) => {
        if (!openNav) {
            document.body.classList.add("showNav");
        } else {
            document.body.classList.remove("showNav");
        }
        return setOpenNav(!openNav);
    };

    const [openSubmenu, setOpenSubmenu] = React.useState(false);
    const [menu, setMenu] = React.useState(null);

    React.useEffect(() => {
        if (!openSubmenu) {
            setOpenSubmenu(null);
        }
    }, [openSubmenu]);

    const showSubmenu = (menu) => {
        if (!openSubmenu) {
            document.body.classList.add("showSubmenu");
            setOpenSubmenu(!openSubmenu);
        }
        setMenu(menu);
    };

    return (
        <>
            <aside className="navigation">
                <div className="nav-wrapper">
                    <div className="primary-nav">
                        <nav className="profile">
                            <button type="button" className="nav-item" aria-label="Profile" title="Profile">
                                {profileImage ? (
                                    <div className="profileImage embed-responsive embed-responsive-1by1">
                                        <img src="profile_v1.jpg" className="embed-responsive-item" alt="Profile" />
                                    </div>
                                ) : (
                                    <span className="material-symbols-rounded">account_circle</span>
                                )}
                            </button>
                        </nav>
                        <nav className="nav-top-level">
                            <ul className="nav-list">
                                {navigation.map((n) => (
                                    <li key={`nav-${n.id}`} id={`nav-${n.id}`}>
                                        {n.submenus?.length > 0 ? (
                                            <button
                                                type="button"
                                                className="nav-item"
                                                aria-label={n.name}
                                                title={n.name}
                                                onClick={() => showSubmenu(n)}
                                            >
                                                <span className="material-symbols-rounded">{n.icon}</span>
                                            </button>
                                        ) : (
                                            <a className="nav-item" aria-label={n.name} title={n.name} href={n.slug}>
                                                <span className="material-symbols-rounded">{n.icon}</span>
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                        <nav className="bottom">
                            <button type="button" className="nav-item" aria-label="Logout" title="Logout">
                                <span className="material-symbols-rounded">logout</span>
                            </button>
                        </nav>
                    </div>
                    <Submenu menu={menu} openSubmenu={openSubmenu} setOpenSubmenu={setOpenSubmenu} />
                </div>
            </aside>
            {openNav && <button type="button" className="closeNav" onClick={toggleMenu}></button>}
        </>
    );
}
