export default function Widget({ label, value, icon, action }) {
    const contents = (
        <>
            <span className="widget-icon material-symbols-rounded">{icon}</span>
            <div className="widget-content">
                <span className="widget-label">{label}</span>
                <span className="widget-value">{value}</span>
            </div>
        </>
    );
    return action ? (
        <button type="button" className="widget">{contents}</button>
    ) : (
        <div className="widget">{contents}</div>
    );
}
