export default function Submenu({ menu, openSubmenu, setOpenSubmenu }) {
    const closeSubmenu = (e) => {
        if (openSubmenu) {
            document.body.classList.remove("showSubmenu");
            setOpenSubmenu(false)
        } 
        return;
    };
    return (
        <section className="submenu">
            <nav>
                {menu === null ? (
                    <h3 className="mx-3">Loading</h3>
                ) : (
                    <>
                        <h3 className="mx-3">{menu.name}</h3>
                        <ul className="submenu-list">
                            {menu.submenus.map(sub => (
                                <li key={`submenu-${sub.id}`} id={`submenu-${sub.id}`}>
                                    <a className="nav-item" href={sub.slug}>
                                        <span className="material-symbols-rounded">{sub.icon}</span>
                                        <span className="submenu-name">{sub.name}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </nav>
            <button type="button" className="close-me white position-right position-tight" aria-label="Close Submenu" onClick={closeSubmenu}></button>
        </section>
    );
}
