export default function Header({ openNav, setOpenNav }) {
    const toggleMenu = (e) => {
        if (!openNav) {
            document.body.classList.add("showNav");
        } else {
            document.body.classList.remove("showNav");
        }
        return setOpenNav(!openNav);
    };
    return (
        <header className="header">
            <div className="container-fluid">
                <nav className="top-nav">
                    <button
                        type="button"
                        className="logo embed-responsive embed-responsive-1by1"
                        onClick={toggleMenu}
                        aria-expanded={openNav}
                        aria-controls="mainNavigation"
                        aria-haspopup="menu"
                        aria-label="Main Navigation"
                    >
                        <img
                            src="logo.svg"
                            className="embed-responsive-item"
                            alt="logo"
                        />
                    </button>
                    <div className="search ml-3 mx-md-3">
                        <form className="global-search">
                            <div className="search-wrapper">
                                <input
                                    className="search-input"
                                    type="search"
                                    placeholder="Search..."
                                />
                                <label className="search-label">
                                    Search...
                                </label>
                                <span className="material-symbols-rounded">
                                    search
                                </span>
                            </div>
                        </form>
                    </div>
                    <ul className="header-buttons">
                        <li>
                            <button
                                type="button"
                                className="nav-item"
                                aria-label="Notifications"
                                title="Notifications"
                            >
                                <span className="material-symbols-rounded">
                                    notifications
                                </span>
                                <span className="count danger">3</span>
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="nav-item"
                                aria-label="Notifications"
                                title="Notifications"
                            >
                                <span className="material-symbols-rounded">
                                    chat_bubble
                                </span>
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="nav-item"
                                aria-label="Notifications"
                                title="Notifications"
                            >
                                <span className="material-symbols-rounded">
                                    contact_support
                                </span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}
