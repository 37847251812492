import React from "react";
import customerData from "./customers/customers.json";
import config from "./data/configuration.json";
import { parseISO, format } from "date-fns";
import Layout from "./layout/layout";
import Widget from "./components/widget";
import "./styles/global.scss";
import CustomersPreview from "./customers/customers.preview";

function App() {
    const customers = customerData.items;

    const [openPreview, setOpenPreview] = React.useState(false);
    const [previewCustomer, setPreviewCustomer] = React.useState(null);
    const [bookmarkedCustomers, setBookmarkedCustomers] = React.useState([]);

    React.useEffect(() => {
        if(!openPreview) {
            setPreviewCustomer(null);
        }
    },[openPreview]) 

    const showPreview = (customer) => {
        if (!openPreview) {
            document.body.classList.add("openPreview");
            setOpenPreview(!openPreview);
        }
        setPreviewCustomer(customer);
    };

    const toggleBookmark = (customerId) => {
        if (bookmarkedCustomers.includes(customerId)) {
            return setBookmarkedCustomers(bookmarkedCustomers.filter(c => c !== customerId));
        }
        setBookmarkedCustomers([...bookmarkedCustomers, customerId])
    };

    return (
        <Layout mainClass="customers" openPreview={openPreview} setOpenPreview={setOpenPreview}>
            <div className="container-fluid">
                <section className="page-title mb-4">
                    <h1>
                        Customers
                        <span className="ml-2 badge badge-secondary">49,660</span>
                    </h1>
                </section>
                <section className="widget-collection widgets-lg-small">
                    <Widget label={"All"} value={"49,660"} icon={"group"} />
                    <Widget label={"Active"} value={"9,711"} icon={"person"} />
                    <Widget label={"Inactive"} value={"39,949"} icon={"person_off"} />
                </section>
                <section className="table-content">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col" className="bulk-select"></th>
                                {/* <th scope="col">ID</th> */}
                                <th scope="col">Customer</th>
                                {/* <th scope="col">Postcode</th> */}
                                <th scope="col">Consumers</th>
                                <th scope="col">Added</th>
                                <th scope="col" className="icon-column">
                                    Booked
                                </th>
                                <th scope="col" className="text-md-right">
                                    Balance
                                </th>
                                <th scope="col" className="icon-column">
                                    Active
                                </th>
                                <th scope="col" className="bookmark-column">
                                    Bookmark
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {customers.map((c) => { 
                                return (
                                <tr key={c.id} id={ c.id} className={`${c.active ? "active" : "inactive"} ${c.status}`}>
                                    <td title={`Select ${c.name}`} className="bulk-select">
                                        <div className="custom-control custom-checkbox bulk-select-checkbox">
                                            <input type="checkbox" className="custom-control-input" id={`select-customer-${c.id}`} />
                                            <label className="custom-control-label" htmlFor={`select-customer-${c.id}`}></label>
                                        </div>
                                    </td>
                                    {/* Make Super Admin only */}
                                    {/* <th scope="row" onClick={() => showPreview(c)}>#{c.id}</th> */}
                                    {/* <td
                                        className={`mobile-icon-right text-md-center ${
                                            c.status === "enabled"
                                                ? "text-success"
                                                : "text-danger"
                                        }`}
                                        title={`Customer ${c.status}`}
                                        onClick={() => showPreview(c)}
                                    >
                                        <span className="material-symbols-rounded">
                                            {c.status === "enabled"
                                                ? "check"
                                                : "block"}
                                        </span>
                                    </td> */}
                                    <td title={`Customer Name`} className="stack-contents" onClick={() => showPreview(c)}>
                                        <span className="mobile-title">{c.name}</span>
                                        <small className="mb-1 mb-md-0">{c.email}</small>
                                    </td>
                                    {/* Optional */}
                                    {/* <td className="mobile-hide" title={`Postcode`} onClick={() => showPreview(c)}>
                                        {c.postcode}
                                    </td> */}
                                    <td title={`Consumers`} onClick={() => showPreview(c)}>
                                        {c.consumers.map((consumer) => (
                                            <span key={`${c.id}-${consumer.id}`}>
                                                {consumer.first_name} {consumer.last_name},{" "}
                                            </span>
                                        ))}
                                    </td>
                                    {/* <td
                                        className={`stack-md-contents mobile-hide ${
                                            c.last_payment ? "" : "empty"
                                        }`}
                                        title={c.last_payment && "Customer's Latest Payment"}
                                        onClick={() => showPreview(c)}
                                    >
                                        {c.last_payment && (
                                            <>
                                                <span
                                                    className={`text-monospace ${
                                                        c.last_payment.amount >
                                                        0
                                                            ? "text-success"
                                                            : c.last_payment
                                                                  .amount < 0
                                                            ? "text-danger"
                                                            : ""
                                                    }`}
                                                >
                                                    {config.CURRENCY_SYMBOL}
                                                    {(
                                                        c.last_payment.amount /
                                                        100
                                                    ).toFixed(2)}
                                                </span>
                                                <small className="ml-2 ml-md-0">
                                                    (
                                                    {format(
                                                        parseISO(
                                                            c.last_payment.dated
                                                        ),
                                                        "do LLL yy"
                                                    )}
                                                    )
                                                </small>
                                            </>
                                        )}
                                    </td> */}
                                    <td className="mobile-hide" title={`Date ${c.name} was added`} onClick={() => showPreview(c)}>
                                        {format(parseISO(c.created_at), "do LLL yy")}
                                    </td>
                                    <td
                                        className={`mobile-hide icon-column ${c.booked ? "text-success" : ""}`}
                                        title={`Customer ${c.booked ? "booked" : "has not booked"}`}
                                        onClick={() => showPreview(c)}
                                    >
                                        <span className="material-symbols-rounded">{c.booked ? "visibility" : "visibility_off"}</span>
                                    </td>

                                    <td className="text-md-right" title={`${c.name}'s Balance`} onClick={() => showPreview(c)}>
                                        <span className={`text-monospace ${c.balance > 0 ? "text-success" : c.balance < 0 ? "text-danger" : ""}`}>
                                            {config.CURRENCY_SYMBOL}
                                            {(c.balance / 100).toFixed(2)}
                                        </span>
                                    </td>
                                    <td
                                        className={`active-column icon-column ${c.active ? "text-success" : ""}`}
                                        title={`${c.name} ${c.active ? "active" : "inactive"}`}
                                        onClick={() => showPreview(c)}
                                    >
                                        <span className="material-symbols-rounded">{c.active ? "person" : "person_off"}</span>
                                    </td>
                                    <td
                                        className={`bookmark-column ${bookmarkedCustomers.includes(c.id) ? "bookmarked" : ""}`}
                                        title={`${c.name} ${bookmarkedCustomers.includes(c.id) ? "bookmarked" : "not bookmarked"}`}
                                        onClick={() => toggleBookmark(c.id)}
                                    >
                                        <span className="material-symbols-rounded">bookmark</span>
                                    </td>
                                </tr>)}
                            )}
                        </tbody>
                    </table>
                </section>
            </div>
            <CustomersPreview customer={previewCustomer} openPreview={openPreview} setOpenPreview={setOpenPreview} />
        </Layout>
    );
}

export default App;
