export default function Preview({ children, openPreview, setOpenPreview, setEditEnabled }) {
    const closePreview = (e) => {
        if (openPreview) {
            document.body.classList.remove("openPreview");
            setOpenPreview(false)
            setEditEnabled(false)
        } 
        return;
    };
    return (
        <aside className="preview">
            {children}
            <button type="button" className="close-me white position-right position-tight" aria-label="Close Preview" onClick={closePreview}></button>
        </aside>
    )
}
